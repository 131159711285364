/* here you can put your own css to customize and override the theme */
table.dataTable thead .sorting_desc_disabled:after {
    display: none !important;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    display: none !important;
}

.datetimepicker-group .datepicker > div {
    display: block;
}

div.dataTables_wrapper div.dataTables_processing {
    z-index: 1;
}

@media only screen and (min-width: 601px) {
    .doc-btn {
        white-space: normal;
        width: 500px;
    }
}

.main-search-box input {
    min-height: 35px !important;
    font-size: 20px !important;
}

.main-search-box .typeahead__item {
    font-size: 13px !important;
}

h1.house-title {
    margin-top: 0;
}

.house-title-wrapper {
    display: flex;
    align-items: center;
}

.house-title-wrapper > span.btn-group {
    margin-left: 10px;
}

/*.house-title-wrapper i {*/
    /*margin: 0 0 6px 10px;*/
    /*color:#3598dc;*/
    /*font-size: 2rem;*/
    /*cursor: pointer;*/
/*}*/

img.house-avatar {
    max-width: 200px;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
    width: 14px !important;
    height: 14px !important;;
}

.no-border {
    border: 0 !important;
}

.form-label {
    padding: 6px 0;
}

.msp-user-block {
    display: inline-block;
}

/* hax to remove .selected color... */
.no-select tr.selected {
    background-color: inherit !important;
    color: inherit !important;
}

.no-select tr.odd {
    background-color: #f9f9f9 !important;
}

.no-select a {
    color: #337ab7 !important;
}

.loader-text {
    position: absolute;
    width: 100%;
    height: 1rem;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 3rem;
    text-align: center;
    color: #f3f3f3;
    text-shadow: 1px 1px rgb(0 0 0 / 10%);
}

/* 12/5 column support */
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}
