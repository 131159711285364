@charset "UTF-8";

.page-header {
    margin: 0;
    padding: 0;
    border-bottom: none;
    /* Navbar */
}

.page-header .navbar {
    border-radius: 0;
    margin-bottom: 0;
    background: inherit;
    /* Container Fluid */
    /* Navbar Toggle */
    /* Navbar Brand */
    /* Search */
    /* Topbar Actions */
    /* Navbar Nav */
}

.page-header .navbar > .container-fluid {
    margin-left: -1px;
    margin-right: -1px;
}

.page-header .navbar .navbar-toggle {
    border: none;
    padding-right: 0;
    margin: 8px 0 7px 20px;
}

.page-header .navbar .navbar-toggle .icon-bar {
    width: 25px;
    background: #606d78;
}

.page-header .navbar .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 5px;
}

.page-header .navbar .page-logo {
    float: left;
    height: 50px;
    max-height: 50px;
    line-height: 50px;
    margin-right: 75px;
}

.page-header .navbar .page-logo img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.page-header .navbar .search {
    top: 10px;
    display: table;
    position: relative;
    margin-bottom: 10px;
}

.page-header .navbar .search .form-control {
    width: 250px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    padding-right: 42px;
    color: #4b5761;
    background: #2a3239;
}

.page-header .navbar .search .form-control:-moz-placeholder {
    color: #4b5761;
}

.page-header .navbar .search .form-control::-moz-placeholder {
    color: #4b5761;
}

.page-header .navbar .search .form-control:-ms-input-placeholder {
    color: #4b5761;
}

.page-header .navbar .search .form-control::-webkit-input-placeholder {
    color: #4b5761;
}

.page-header .navbar .search .btn {
    padding: 0;
    margin: 0;
    top: 9px;
    right: 15px;
    position: absolute;
    color: #4b5761;
    z-index: 200;
}

.page-header .navbar .topbar-actions {
    position: absolute;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-v2 {
    padding: 0;
    min-width: 300px;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-v2:before {
    border-color: transparent transparent #21282e transparent;
}

.page-header .navbar .topbar-actions .btn-group-notification .btn {
    top: 6px;
    background: #009dc7;
    width: 35px;
    height: 35px;
}

.page-header .navbar .topbar-actions .btn-group-notification .btn i {
    font-size: 15px;
    line-height: 1.6;
    color: #fff;
}

.page-header .navbar .topbar-actions .btn-group-notification .btn i:hover {
    color: #fff;
}

.page-header .navbar .topbar-actions .btn-group-notification .btn .badge {
    top: -10px;
    right: -10px;
    height: 21px;
    color: #62717f;
    min-width: 21px;
    font-weight: 700;
    line-height: 1.4;
    position: absolute;
    border: 2px solid #2f373e;
    font-size: 9px !important;
    background: #fff;
}

.page-header .navbar .topbar-actions .btn-group-notification .external {
    padding: 15px;
    display: block;
    overflow: hidden;
    background: #21282e;
    letter-spacing: 1px;
}

.page-header .navbar .topbar-actions .btn-group-notification .external h3 {
    margin: 0;
    z-index: 1;
    padding: 0;
    float: left;
    color: #bbb;
    font-size: 13px;
    line-height: 1.4;
    max-width: 200px;
    position: relative;
}

.page-header .navbar .topbar-actions .btn-group-notification .external a {
    padding: 0;
    clear: none;
    font-size: 13px;
    font-weight: 300;
    text-align: right;
    color: #009dc7;
    text-transform: lowercase;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li {
    display: block;
    padding: 15px;
    border-top: 1px solid #2e353c;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li:before, .page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li:after {
    display: table;
    content: " ";
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li:after {
    clear: both;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li:first-child {
    border-top: none;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li:hover {
    background: #21282e;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li > a {
    color: #606e7a;
    overflow: hidden;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li > a .details {
    float: left;
    max-width: 215px;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li > a .details .label {
    float: left;
    padding: 5px;
    margin-right: 10px;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li > a .time {
    float: right;
    font-size: 12px;
    padding: 4px 8px;
    background: #2a3239;
}

.page-header .navbar .topbar-actions .btn-group-notification .dropdown-menu-list > li > a:hover {
    text-decoration: none;
}

.page-header .navbar .topbar-actions .btn-group-red {
    position: relative;
    margin: 0 30px 0 15px;
}

.page-header .navbar .topbar-actions .btn-group-red:after {
    top: 11px;
    width: 1px;
    content: " ";
    right: -17.5px;
    position: absolute;
    border: 25px #3e4952;
    border-top-style: solid;
}

.page-header .navbar .topbar-actions .btn-group-red .btn {
    top: 6px;
    padding: 4px;
    position: relative;
    background: #d93d5e;
    color: #fff;
    width: 35px;
    height: 35px;
}

.page-header .navbar .topbar-actions .btn-group-red .btn > i {
    font-size: 20px;
    line-height: 0.9;
}

.page-header .navbar .topbar-actions .btn-group-img .btn {
    top: 7px;
    padding: 0;
    position: relative;
    line-height: normal;
    background: #333c43;
    height: 35px;
}

.page-header .navbar .topbar-actions .btn-group-img .btn > span {
    padding: 9px;
    line-height: 1.4;
    display: inline-block;
    vertical-align: middle;
    color: #009dc7;
    text-transform: uppercase;
}

.page-header .navbar .topbar-actions .btn-group-img .btn > img {
    margin-top: -1px;
    text-align: center;
    vertical-align: middle;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background-clip: padding-box;
    height: 35px;
}

.page-header .navbar .navbar-nav > li > a {
    color: #636e77;
    font-size: 18px;
    font-weight: 400;
    padding: 22px 40px;
    background: #39424a;
    font-family: 'Open Sans', sans-serif;
}

.page-header .navbar .navbar-nav > li > a > i {
    position: relative;
    top: -2px;
    right: 5px;
}

.page-header .navbar .navbar-nav > li:hover > a {
    color: #fff;
    background: #009dc7;
}

.page-header .navbar .navbar-nav > li.more-dropdown .dropdown-menu > li > a:hover,
.page-header .navbar .navbar-nav > li .more-dropdown-sub .dropdown-menu > li > a:hover {
    background: #f1f1f1;
}

.page-header .navbar .navbar-nav > li.more-dropdown .dropdown-menu > li.active > a,
.page-header .navbar .navbar-nav > li .more-dropdown-sub .dropdown-menu > li.active > a {
    /*background: #f1f1f1;*/
}

.page-header .navbar .navbar-nav > li.open > a {
    background: #f9f9f9;
    color: #2a3239;
}

.page-header .navbar .navbar-nav > li.open:hover > a {
    background: #f9f9f9;
    color: #2a3239;
}

.page-header .navbar .navbar-nav > li.selected > a {
    color: #fff;
}

.page-header .navbar .navbar-nav > li.root.no-child.selected > a {
    background: #f9f9f9;
    color: #2a3239;
}

.page-header .navbar .navbar-nav > li.open.selected > a {
    color: #2a3239;
}

.page-header .navbar .navbar-nav .dropdown-menu {
    z-index: 10;
    border: none;
    box-shadow: none;
    margin-top: -4px;
    border-radius: 0;
    background: #f9f9f9;
}

.page-header .navbar .navbar-nav .dropdown-menu:before, .page-header .navbar .navbar-nav .dropdown-menu:after {
    display: none !important;
}

.page-header .navbar .navbar-nav .dropdown-menu > li {
    display: inline-block;
}

.page-header .navbar .navbar-nav .dropdown-menu > li > a {
    color: #6c7b88;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    padding: 30px 15px 30px 30px;
    font-family: "Open Sans", sans-serif;
}

.page-header .navbar .navbar-nav .dropdown-menu > li > a:focus, .page-header .navbar .navbar-nav .dropdown-menu > li > a:hover {
    background: inherit;
    color: #009dc7;
}

.page-header .navbar .navbar-nav .dropdown-menu > li > a:focus > i, .page-header .navbar .navbar-nav .dropdown-menu > li > a:hover > i {
    color: #009dc7;
}

.page-header .navbar .navbar-nav .dropdown-menu li.active > a {
    background: inherit;
    color: #009dc7;
}

.page-header .navbar .navbar-nav .dropdown-menu li.active > a > i {
    color: #009dc7;
}

.page-portlet-fullscreen .page-header {
    display: none;
}

/* Material Design */
.page-md {
    /* Topbar Actions */
    /* Dropdown Menu */
}

.page-md .topbar-actions .btn-group-notification .btn {
    overflow: visible !important;
}

.page-md .topbar-actions .btn-group-notification .btn .badge {
    padding: 3px 6px 2px;
}

.page-md .navbar-collapse .dropdown .dropdown-menu {
    box-shadow: none !important;
}

.page-md .navbar-collapse .more-dropdown-sub .dropdown-menu,
.page-md .navbar-collapse .more-dropdown .dropdown-menu {
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1) !important;
}

/* Bootstrap collapse nav at max-width: 991px */
@media (max-width: 991px) {
    /* 991px */
    .page-header .navbar-toggle {
        display: block;
    }

    .page-header .navbar-collapse.collapse {
        display: none !important;
    }

    .page-header .navbar-collapse.collapse.in {
        display: block !important;
        overflow-y: auto !important;
    }

    .page-header .navbar-nav {
        margin: 0;
        float: none !important;
    }

    .page-header .navbar-nav > li {
        float: none;
    }
}

/* Stylesheet design for under max-width: 480px */
@media (max-width: 480px) {
    /* 480px */
    .page-header {
        /* Search */
        /*Topbar Actions*/
    }

    .page-header .search .form-control {
        width: 130px !important;
    }

    /*.page-header .topbar-actions {*/
        /*float: right;*/
        /*margin-bottom: 15px;*/
        /*position: static !important;*/
    /*}*/

    .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2 {
        right: -125px;
    }

    .page-header .topbar-actions .btn-group-notification .dropdown-menu-v2:before {
        top: -10px;
        left: 150px;
        border-width: 0 8px 15px 8px;
        border-color: transparent transparent #242b31 transparent;
    }

    .page-header .topbar-actions .btn-group-red .dropdown-menu-v2 {
        right: -70px;
    }

    .page-header .topbar-actions .btn-group-red .dropdown-menu-v2:before {
        top: -10px;
        right: 80px;
        border-width: 0 8px 15px 8px;
        border-color: transparent transparent #242b31 transparent;
    }
}

/* Stylesheet design for under max-width: 768px */
@media (max-width: 768px) {
    /* 768px */
    .page-header {
        /* Page Logo */
        /* Topbar Actions */
    }

    .page-header .page-logo {
        display: block;
        float: none !important;
    }

    .page-header .topbar-actions .btn-group-img .btn > span {
        display: none !important;
    }
}

/* Stylesheet design for under max-width: 991px */
@media (max-width: 991px) {
    /* 991px */
    .page-header {
        /* Navbar Fixed Top */
        /* Page Logo */
        /* Search */
        /* Topbar Actions */
        /* Navbar Collapse */
    }

    .page-header .navbar-fixed-top {
        position: static;
        margin: 10px auto;
    }

    .page-header .page-logo {
        float: none !important;
    }

    .page-header .search {
        display: inherit !important;
    }

    .page-header .search .form-control {
        height: 45px;
        width: 100% !important;
    }

    .page-header .search .btn i {
        top: 14px !important;
    }

    .page-header .topbar-actions {
        top: 10px;
        right: 60px;
    }

    .page-header .nav-collapse {
        box-shadow: none;
        border-top: none;
    }

    .page-header .nav-collapse .navbar-nav {
        margin-bottom: 15px;
        /* More Dropdown Sub */
    }

    .page-header .nav-collapse .navbar-nav > li {
        border-top: 1px solid #414b54;
    }

    .page-header .nav-collapse .navbar-nav > li:first-child {
        border-top: none;
    }

    .page-header .nav-collapse .navbar-nav > li > a {
        position: relative;
    }

    .page-header .nav-collapse .navbar-nav > li > a:after {
        top: 20px;
        right: 20px;
        color: #8897a1;
        font-size: 16px;
        content: "\f105";
        position: absolute;
        font-weight: normal;
        display: inline-block;
        font-family: FontAwesome, sans-serif;
    }

    .page-header .nav-collapse .navbar-nav > li:hover > a:after {
        color: #fff;
    }

    .page-header .nav-collapse .navbar-nav li.open > a:after {
        color: #6c7b88 !important;
        content: "\f107";
    }

    .page-header .nav-collapse .navbar-nav li.selected > a {
        background: #009dc7;
    }

    .page-header .nav-collapse .navbar-nav li.selected > a:after {
        color: #fff;
    }

    .page-header .nav-collapse .navbar-nav li.selected.open > a {
        background: #f9f9f9;
    }

    .page-header .nav-collapse .navbar-nav .dropdown-menu > li > a {
        padding: 10px 15px 10px 50px;
    }

    .page-header .nav-collapse .navbar-nav .dropdown-menu > li > a:hover {
        color: #009dc7 !important;
    }

    .page-header .nav-collapse .navbar-nav .more-dropdown-sub > a:after {
        top: 10px;
        right: 20px;
        color: #6c7b88;
        font-size: 16px;
        content: "\f105";
        position: absolute;
        font-weight: normal;
        display: inline-block;
        font-family: FontAwesome, sans-serif;
    }

    .page-header .nav-collapse .navbar-nav .more-dropdown-sub:focus > a, .page-header .nav-collapse .navbar-nav .more-dropdown-sub:hover > a {
        color: #636e77 !important;
    }

    .page-header .nav-collapse .navbar-nav .more-dropdown-sub.open > a:after {
        color: #6c7b88;
        content: "\f107";
    }

    .page-header .nav-collapse .navbar-nav .more-dropdown-sub.open > a:focus {
        color: #636e77;
    }

    .page-header .nav-collapse .navbar-nav .more-dropdown-sub.open .dropdown-menu {
        margin-left: 15px;
    }
}

/* Stylesheet design for above 767px */
@media (min-width: 767px) {
    /* 767px */
    .page-header {
        /* Nav Collapse */
    }

    .page-header .nav-collapse {
        padding: 0;
    }
}

/* Stylesheet design for above 992px */
@media (min-width: 992px) {
    /* 992px */
    /* Page On Scroll */
    .page-on-scroll .page-header {
        /* Navbar Fixed Top */
        /* Topbar Actions */
        /* Dropdown Menu */
    }

    .page-on-scroll .page-header .navbar-fixed-top {
        padding: 5px 25px;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    }

    .page-on-scroll .page-header .topbar-actions {
        top: 8px;
        right: 25px;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    }

    .page-on-scroll .page-header .dropdown-menu-v2 {
        margin-top: 21px;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    }

    .page-header {
        /* Navbar Fixed Top */
        /* Topbar Actions */
        /* Navbar Collapse */
    }

    .page-header .navbar-fixed-top {
        top: 0;
        left: 0;
        right: 0;
        padding: 20px 25px;
        background: #2f373e;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    }

    .page-header .topbar-actions {
        top: 20px;
        right: 25px;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    }

    .page-header .navbar-collapse {
        margin-top: 83px;
    }

    .page-header .navbar-collapse .navbar-nav {
        /* More Dropdown */
        /* More Dropdown Sub */
    }

    .page-header .navbar-collapse .navbar-nav > li {
        margin-left: 2px;
    }

    .page-header .navbar-collapse .navbar-nav > li:first-child {
        margin-left: 0;
    }

    .page-header .navbar-collapse .navbar-nav > li > a {
        -webkit-border-top-left-radius: 2px;
        -moz-border-top-left-radius: 2px;
        -ms-border-top-left-radius: 2px;
        -o-border-top-left-radius: 2px;
        border-top-left-radius: 2px;
        background-clip: padding-box;
        -webkit-border-top-right-radius: 2px;
        -moz-border-top-right-radius: 2px;
        -ms-border-top-right-radius: 2px;
        -o-border-top-right-radius: 2px;
        border-top-right-radius: 2px;
    }

    .page-header .navbar-collapse .navbar-nav li.opened > .dropdown-menu {
        display: block;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown > a {
        padding-right: 57px;
        position: relative;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown > a:after {
        top: 22px;
        right: 30px;
        /*color: #6c7b88;*/
        font-size: 20px;
        content: "\f107";
        position: absolute;
        font-weight: normal;
        display: inline-block;
        font-family: FontAwesome, sans-serif;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown:hover > a {
        background: #f9f9f9;
        color: #2a3239;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown:hover > a:after {
        color: #fff;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown .dropdown-menu {
        min-width: 230px;
        padding: 10px auto;
        box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown .dropdown-menu > li {
        display: block;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown .dropdown-menu > li > a {
        padding: 8px 30px;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown:hover > .dropdown-menu {
        display: block;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown.open > a:after {
        color: #2a3239;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub > a {
        padding: 30px !important;
        position: relative;
        background: inherit;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub > a:after {
        top: 30px;
        right: 10px;
        color: #6c7b88;
        font-size: 16px;
        content: "\f107";
        position: absolute;
        font-weight: normal;
        display: inline-block;
        font-family: FontAwesome, sans-serif;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub:focus > a, .page-header .navbar-collapse .navbar-nav .more-dropdown-sub:hover > a {
        color: #009dc7 !important;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub:hover > a:after {
        color: #009dc7;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub .dropdown-menu {
        min-width: 230px;
        padding: 10px auto;
        box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub .dropdown-menu > li {
        display: block;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub .dropdown-menu > li > a {
        padding: 8px 30px;
    }

    .page-header .navbar-collapse .navbar-nav .more-dropdown-sub:hover > .dropdown-menu {
        display: block;
    }
}

/*Mega Menu under max-width: 991px */
@media (max-width: 991px) {
    /* 991px */
    .mega-menu .dropdown-menu {
        left: 0;
        right: 0;
        float: none !important;
        width: auto;
        position: static !important;
    }

    .mega-menu .dropdown-menu > li {
        display: block !important;
    }
}

/* Mega Menu above 991px */
@media (min-width: 991px) {
    /* 991px */
    .mega-menu .nav,
    .mega-menu .collapse,
    .mega-menu .dropup {
        position: static;
    }

    .mega-menu .dropdown-fw {
        position: static;
    }

    .mega-menu .dropdown-menu-fw {
        left: 15px;
        right: 15px;
    }
}

/*--------------------------------------------------
    [PAGE SIDEBAR]
----------------------------------------------------*/
.page-content-container {
    overflow: hidden;
}

.page-content-row {
    width: 100%;
    display: table;
    table-layout: fixed;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.page-content-row .page-sidebar {
    display: table-cell;
    vertical-align: top;
    width: 225px;
    min-width: 225px;
    margin: 0;
    padding: 0;
    /* Navbar Collapse */
    /* Navbar Nav */
}

.page-content-row .page-sidebar h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
    margin: 0 0 15px;
    text-transform: uppercase;
    color: #2a3239;
    font-family: 'Open Sans', sans-serif;
}

.page-content-row .page-sidebar .navbar-nav {
    float: none;
    padding: 0;
    margin: 0 0 30px 0;
}

.page-content-row .page-sidebar .navbar-nav > li {
    float: none;
    display: block;
    margin-top: 2px;
}

.page-content-row .page-sidebar .navbar-nav > li:first-child {
    margin-top: 0;
}

.page-content-row .page-sidebar .navbar-nav > li > a {
    display: block;
    color: #6c7b88;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    background: #f4f7f8;
    text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background-clip: padding-box;
}

.page-content-row .page-sidebar .navbar-nav > li > a > i {
    color: #5c7b88;
    margin-right: 7px;
}

.page-content-row .page-sidebar .navbar-nav > li > a .label {
    font-size: 11px;
    margin: 1px 0 0;
    float: right !important;
}

.page-content-row .page-sidebar .navbar-nav > li > a .badge {
    margin-top: 1px;
    float: right !important;
}

.page-content-row .page-sidebar .navbar-nav > li > a:hover {
    color: #fff;
    background: #009dc7;
}

.page-content-row .page-sidebar .navbar-nav > li > a:hover > i {
    color: #fff;
}

.page-content-row .page-sidebar .navbar-nav li.active > a {
    color: #fff;
    background: #009dc7;
}

.page-content-row .page-sidebar .navbar-nav li.active > a > i {
    color: #fff;
}

.page-content-row .page-content-col {
    vertical-align: top;
    display: table-cell;
    padding-left: 20px;
}

/* Material Design */
.page-md .page-sidebar .navbar-nav {
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
    /* 991px */
    /* Navbar Toggle */
    .navbar-toggle {
        display: inline-block;
        border: none;
        padding-right: 0;
        margin: 8px 0 7px 20px;
    }

    .navbar-toggle .icon-bar {
        width: 25px;
        background: #606d78;
    }

    .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 5px;
    }

    .page-content-row {
        width: 100%;
        display: block;
    }

    .page-content-row .page-sidebar {
        overflow-y: hidden;
        display: block;
        height: 0;
        margin-bottom: 20px;
        width: 100%;
        min-width: 100%;
    }

    .page-content-row .page-sidebar.in {
        height: auto;
        display: block;
    }

    .page-content-row .page-content-col {
        padding-left: 0;
        display: block;
    }

    .page-content-row .page-content-col .page-content {
        padding-top: 0;
    }
}

/*--------------------------------------------------
	[PAGE CONTENT]
----------------------------------------------------*/
/* Body */
body {
    font-size: 14px;
    background: #2f373e;
}

/* Go2top */
.go2top i,
.go2top i:focus {
    right: 27px;
    bottom: 52px;
    width: 38px;
    height: 38px;
    z-index: 999;
    line-height: 1;
    font-size: 35px;
    position: fixed;
    color: #4b5761;
}

.page-portlet-fullscreen .go2top i {
    z-index: -1;
}

/* Wrapper for above 991px */
@media (min-width: 991px) {
    /* 991px */
    .wrapper {
        margin: 10px;
    }
}

/* Page Content */
.page-content {
    padding: 30px;
    min-height: 500px;
    margin-bottom: 15px;
    background: #fff;
}

@media (max-width: 992px) {
    /* 992px */
    .page-content {
        margin-top: -1px;
    }
}

@media (min-width: 992px) {
    /* 991px */
    .page-content {
        margin-top: -4px;
        position: relative;
        -webkit-border-top-right-radius: 2px;
        -moz-border-top-right-radius: 2px;
        -ms-border-top-right-radius: 2px;
        -o-border-top-right-radius: 2px;
        border-top-right-radius: 2px;
        background-clip: padding-box;
        -webkit-border-bottom-left-radius: 2px;
        -moz-border-bottom-left-radius: 2px;
        -ms-border-bottom-left-radius: 2px;
        -o-border-bottom-left-radius: 2px;
        border-bottom-left-radius: 2px;
        -webkit-border-bottom-right-radius: 2px;
        -moz-border-bottom-right-radius: 2px;
        -ms-border-bottom-right-radius: 2px;
        -o-border-bottom-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
}

/*--------------------------------------------------
	[BREADCRUMBS]
----------------------------------------------------*/
.breadcrumbs {
    overflow: hidden;
    margin-bottom: 30px;
    padding-right: 6px;
}

.breadcrumbs h1 {
    float: left;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    margin: 0 20px 0 0;
    text-transform: uppercase;
    color: #2a3239;
    font-family: 'Open Sans', sans-serif;
}

.breadcrumbs .breadcrumb {
    margin: 0;
    padding: 0;
    text-align: right;
    background: inherit;
}

.breadcrumbs .breadcrumb > li {
    font-size: 13px;
}

.breadcrumbs .breadcrumb > li + li:before {
    top: 2px;
    color: #dfe4e6;
    font-size: 20px;
    content: "\2022";
    position: relative;
    padding: 0 8px 0 5px;
}

.breadcrumbs .breadcrumb > li > a {
    color: #6c7b88;
    text-transform: uppercase;
}

.breadcrumbs .breadcrumb > li > a:hover {
    text-decoration: none;
    color: #009dc7;
}

.breadcrumbs .breadcrumb li.active {
    color: #009dc7;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    /* 991px */
    .breadcrumbs {
        overflow: hidden;
        text-align: left;
        margin-bottom: 10px;
    }

    .breadcrumbs h1 {
        float: none;
        margin-bottom: 20px;
    }

    .breadcrumbs .breadcrumb {
        text-align: left;
    }
}

/*--------------------------------------------------
	[Copyright]
----------------------------------------------------*/
.copyright {
    color: #bbb;
    margin-bottom: 5px;
}

/* Stylesheet design for under max-width: 991px */
@media (max-width: 991px) {
    /* 991px */
    .copyright {
        margin-bottom: 15px;
    }
}

/******************
Page Quick Sidebar
******************/
/***
Quick Sidebar Layout
***/
/* Page Portlet Fullscreen */
.page-portlet-fullscreen .page-quick-sidebar-wrapper,
.page-portlet-fullscreen .page-quick-sidebar-toggler {
    z-index: -1;
}

/* Quick sidebar toggler */
.page-header .mega-menu .quick-sidebar-toggler {
    top: 9px;
    border: none;
    outline: none;
    padding: 5px 5px;
    margin-left: 15px;
    position: relative;
    background: #45b6af;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background-clip: padding-box;
    width: 35px;
    height: 35px;
}

.page-header .mega-menu .quick-sidebar-toggler i {
    position: relative;
    top: 1px;
    color: #fff;
}

.page-header .mega-menu .quick-sidebar-toggler i:before {
    content: "" /*rtl:""*/;
}

.page-quick-sidebar-open .page-header .mega-menu .quick-sidebar-toggler i:before {
    content: "" /*rtl:""*/;
}

/* Page Portlet Fullscreen */
.page-portlet-fullscreen .page-quick-sidebar-wrapper,
.page-portlet-fullscreen .page-quick-sidebar-toggler {
    z-index: -1;
}

/* Quick sidebar toggler */
.page-quick-sidebar-toggler {
    overflow: hidden;
    z-index: 99999;
    display: none;
    width: 28px;
    height: 27px;
    position: fixed;
    top: 10px;
    right: 15px;
    text-align: center;
    padding-top: 6px;
}

.page-quick-sidebar-toggler:hover {
    background: #303a43;
}

.page-quick-sidebar-open .page-quick-sidebar-toggler {
    display: inline-block;
}

.page-quick-sidebar-open .page-quick-sidebar-toggler:hover {
    background: none;
}

.page-quick-sidebar-toggler > i {
    color: #99a8b5;
    font-size: 17px;
}

.page-quick-sidebar-toggler > i:hover {
    color: #fff !important;
}

.page-quick-sidebar-open .page-quick-sidebar-toggler > i:before {
    content: "" /*rtl:""*/;
}

/* Quick sidebar wrapper */
.page-quick-sidebar-wrapper {
    transition: right 0.3s;
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 320px;
    right: -320px;
    overflow: hidden;
    color: #99a8b5;
    background: #21282e;
}

.page-quick-sidebar-open .page-quick-sidebar-wrapper {
    transition: right 0.3s;
    right: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar {
    background: #21282e;
    /* Quick sidebar tabs content */
    /* Quick sidebar general list heading */
    /* Quick sidebar general list-items */
    /* Inner content */
    /* Quick sidebar list */
    /* Quick sidebar list item */
    /* Quick sidebar list item shown */
    /* Quick sidebar chat */
    /* Quick sidebar alerts */
    /* Quick sidebar settings */
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs {
    margin: 0;
    padding: 0;
    border: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li {
    display: table-cell !important;
    width: 1% !important;
    padding: 0;
    margin: 0;
    float: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li > a {
    position: relative;
    display: block;
    text-align: center;
    border: 0;
    height: auto;
    font-size: 14px;
    padding: 45px 15px 8px;
    text-transform: uppercase;
    background: none;
    margin-right: 0;
    color: #90a1af;
    border-bottom: 3px solid rgba(243, 86, 93, 0.3);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-clip: padding-box;
    outline: none !important;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li > a > .badge {
    position: absolute;
    top: 45px;
    right: 3px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li.active > a, .page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li:hover > a {
    border: 0;
    border-bottom: 3px solid #f3565d;
    background: none;
    color: #fff;
    text-decoration: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu {
    border: 0;
    background: #36424c;
    box-shadow: 5px 5px rgba(97, 117, 135, 0.1);
    margin-top: 8px;
    margin-right: 20px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 19px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #36424c;
    content: '';
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 20px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 7px solid #36424c;
    content: '';
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li > a {
    padding: 10px 15px;
    color: #99a8b5;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li > a > i {
    color: #93a3b1;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li:hover > a {
    background: #3d4a55;
    color: #99a8b5;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li:hover > a > i {
    color: #9babb8;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li.active > a {
    background: #38444f;
    color: #99a8b5;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li .dropdown-menu > li.divider {
    background-color: #3d4a55;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .nav-tabs > li.open > a.dropdown-toggle {
    border-bottom: 3px solid #f3565d;
    background: none;
    text-decoration: none;
    color: #90a1af;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .tab-content {
    margin: 0;
    padding: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-heading {
    font-size: 16px;
    margin: 10px 10px;
    color: #6c8296;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li {
    margin: 0;
    padding: 15px;
    background: none;
    border-bottom: 1px solid #273037;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li:hover {
    background: #273037;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items > li:last-child {
    border-bottom: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .list-items.borderless li {
    border: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .inner-content {
    margin: 10px 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-list {
    position: absolute !important;
    width: 320px !important;
    transition: margin 0.3s;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item {
    position: absolute !important;
    width: 320px !important;
    transition: margin 0.3s;
    margin-left: 320px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav {
    padding: 15px 10px 0 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list {
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    color: #90a1af;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list:hover {
    text-decoration: none;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-item .page-quick-sidebar-nav .page-quick-sidebar-back-to-list > i {
    font-size: 17px;
    line-height: 17px;
    vertical-align: top;
    margin-right: 3px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list {
    transition: margin 0.3s;
    margin-left: -320px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollBar,
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-list .slimScrollRail {
    display: none !important;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-content-item-shown .page-quick-sidebar-item {
    transition: margin 0.3s;
    margin-left: 0;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users {
    padding: 10px 0;
    position: relative;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media {
    padding: 15px 15px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object {
    border-radius: 50% !important;
    width: 46px;
    opacity: 0.8;
    filter: alpha(opacity=80);
    float: left;
    margin-right: 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:before, .page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
    content: " ";
    display: table;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-object:after {
    clear: both;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover {
    cursor: pointer;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media:hover .media-object {
    opacity: 1;
    filter: alpha(opacity=100);
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading {
    margin: 5px 0 0 0;
    font-size: 14px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
    font-size: 11px;
    text-transform: uppercase;
    color: #657b8d;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-body .media-heading-small {
    font-size: 10px;
    color: #5d7081;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-users .media-list .media .media-status {
    margin-top: 10px;
    right: 10px;
    position: absolute;
    display: inline-block;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages {
    padding: 0 10px;
    position: relative;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post {
    transition: display 0.3s;
    padding: 5px 0;
    margin: 10px auto;
    font-size: 13px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .body {
    color: #c3c3c3;
    display: block;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .avatar {
    width: 46px;
    border-radius: 50% !important;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .avatar {
    float: left;
    margin-right: 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .avatar {
    float: right;
    margin-left: 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .name {
    font-size: 12px;
    font-weight: 300;
    color: #8496a7;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .datetime {
    font-size: 12px;
    font-weight: 300;
    color: #8496a7;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post .message {
    display: block;
    padding: 5px;
    position: relative;
    color: #90a1af;
    background: #36424c;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message {
    text-align: left;
    margin-left: 55px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.in .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 6px solid #36424c;
    border-bottom: 6px solid transparent;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message {
    margin-right: 55px;
    text-align: right;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    right: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #36424c;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .name,
.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-messages .post.out .datetime {
    text-align: right;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-chat .page-quick-sidebar-chat-user .page-quick-sidebar-chat-user-form {
    padding: 20px 10px 15px 10px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list {
    padding: 10px 0;
    position: relative;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a {
    color: #7e91a2;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .label {
    margin-top: 5px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .desc {
    text-decoration: underline;
    padding: 0;
    color: #788c9e;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-alerts .page-quick-sidebar-alerts-list .feeds li a .date {
    color: #5d7081;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list {
    padding: 10px 0;
    position: relative;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .bootstrap-switch {
    margin-top: -3px;
    float: right;
    border: 0;
    min-width: 59px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li .form-control {
    width: 75px !important;
    padding: 4px 4px !important;
    float: right;
    border: 0;
    margin-top: -4px;
}

.page-quick-sidebar-wrapper .page-quick-sidebar .page-quick-sidebar-settings .page-quick-sidebar-settings-list .list-items li select.form-control {
    padding: 4px 0 !important;
}

.quick-nav {
    position: fixed;
    z-index: 10103;
    top: 50%;
    right: 10px;
    margin-top: -230px;
    pointer-events: none;
}

.quick-nav .quick-nav-bg {
    /* this is the stretching navigation background */
    position: absolute;
    z-index: 10102;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 30px !important;
    background: #36C6D3;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    -webkit-transition: height .2s, box-shadow .2s;
    -moz-transition: height .2s, box-shadow .2s;
    -ms-transition: height .2s, box-shadow .2s;
    -o-transition: height .2s, box-shadow .2s;
    transition: height .2s, box-shadow .2s;
}

.quick-nav.nav-is-visible {
    pointer-events: auto;
}

.quick-nav.nav-is-visible .quick-nav-bg {
    height: 100%;
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
}

.quick-nav-trigger {
    position: absolute;
    z-index: 10103;
    top: 0;
    right: 0;
    height: 60px;
    width: 60px;
    border-radius: 50% !important;
    overflow: hidden;
    white-space: nowrap;
    color: transparent;
    pointer-events: auto;
}

.quick-nav-trigger span,
.quick-nav-trigger span::after,
.quick-nav-trigger span::before {
    /* this is the hamburger icon */
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #ffffff;
}

.quick-nav-trigger span {
    /* middle line of the hamburger icon */
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    -ms-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    transition: background-color 0.2s;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.quick-nav-trigger span::after,
.quick-nav-trigger span::before {
    /* top and bottom lines of the hamburger icon */
    content: '';
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: transform 0.2s;
    -moz-transition: transform 0.2s;
    -ms-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    transition: transform 0.2s;
}

.quick-nav-trigger span::before {
    -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    transform: translateY(-6px);
}

.quick-nav-trigger span::after {
    -webkit-transform: translateY(6px);
    -moz-transform: translateY(6px);
    -ms-transform: translateY(6px);
    -o-transform: translateY(6px);
    transform: translateY(6px);
}

.no-touch .quick-nav-trigger:hover ~ .quick-nav-bg {
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
}

.nav-is-visible .quick-nav-trigger span {
    background-color: transparent;
}

.nav-is-visible .quick-nav-trigger span::before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.nav-is-visible .quick-nav-trigger span::after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.quick-nav ul {
    position: relative;
    z-index: 10103;
    padding: 60px 0 0;
    visibility: hidden;
    -webkit-transition: visibility 0.3s;
    -moz-transition: visibility 0.3s;
    -ms-transition: visibility 0.3s;
    -o-transition: visibility 0.3s;
    transition: visibility 0.3s;
    text-align: right;
    list-style: none;
}

.quick-nav ul > li a {
    position: relative;
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 calc(1em + 60px) 0 1em;
    font-size: 1.4rem;
    -webkit-transition: color 0.2s;
    -moz-transition: color 0.2s;
    -ms-transition: color 0.2s;
    -o-transition: color 0.2s;
    transition: color 0.2s;
}

.quick-nav ul > li a:hover {
    text-decoration: none;
}

.quick-nav ul > li a:hover > span {
    text-decoration: none;
}

.quick-nav ul > li a > i {
    /* navigation item icons */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    font-size: 18px;
    right: 24px;
    top: 16px;
    color: #ebebeb;
}

.quick-nav ul > li a::before {
    /* line visible next to the active navigation item */
    content: '';
    position: absolute;
    width: 3px;
    height: 16px;
    top: 50%;
    right: 60px;
    -webkit-transform: translateX(3px) translateY(-50%) scaleY(0);
    -moz-transform: translateX(3px) translateY(-50%) scaleY(0);
    -ms-transform: translateX(3px) translateY(-50%) scaleY(0);
    -o-transform: translateX(3px) translateY(-50%) scaleY(0);
    transform: translateX(3px) translateY(-50%) scaleY(0);
    background-color: #FF3F3F;
}

.quick-nav ul > li span {
    /* navigation item labels */
    color: #ebebeb;
    font-weight: 400;
    display: block;
    opacity: 0;
    -webkit-transform: translateX(-25px);
    -moz-transform: translateX(-25px);
    -ms-transform: translateX(-25px);
    -o-transform: translateX(-25px);
    transform: translateX(-25px);
}

.quick-nav ul > li:last-child {
    padding-bottom: 10px;
}

.quick-nav.nav-is-visible ul {
    visibility: visible;
}

.quick-nav.nav-is-visible ul a::after {
    /* navigation item icons */
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-animation: scaleIn 0.15s backwards;
    -o-animation: scaleIn 0.15s backwards;
    animation: scaleIn 0.15s backwards;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.quick-nav.nav-is-visible ul a:hover::after {
    opacity: 1;
}

.quick-nav.nav-is-visible ul a:hover::before {
    -webkit-transform: translateX(3px) translateY(-50%) scaleY(2);
    -moz-transform: translateX(3px) translateY(-50%) scaleY(2);
    -ms-transform: translateX(3px) translateY(-50%) scaleY(2);
    -o-transform: translateX(3px) translateY(-50%) scaleY(2);
    transform: translateX(3px) translateY(-50%) scaleY(2);
    -webkit-transition: transform 0.15s 0.3s;
    -moz-transition: transform 0.15s 0.3s;
    -ms-transition: transform 0.15s 0.3s;
    -o-transition: transform 0.15s 0.3s;
    transition: transform 0.15s 0.3s;
}

.quick-nav.nav-is-visible ul a:hover > span {
    color: white;
}

.quick-nav.nav-is-visible ul a:hover > i {
    color: #fafafa;
}

.quick-nav.nav-is-visible ul span {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: slideIn 0.15s backwards;
    -o-animation: slideIn 0.15s backwards;
    animation: slideIn 0.15s backwards;
    -webkit-transition: transform 0.2s;
    -moz-transition: transform 0.2s;
    -ms-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    transition: transform 0.2s;
}

.no-touch .quick-nav.nav-is-visible ul a:hover::after {
    opacity: 1;
}

.no-touch .quick-nav.nav-is-visible ul a:hover span {
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px);
}

.quick-nav-overlay {
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 10101;
    background: transparent;
}

.quick-nav.nav-is-visible + .quick-nav-overlay {
    background: rgba(0, 0, 0, 0.8);
    display: block;
    -webkit-transition: background .7s ease-out;
    -moz-transition: background .7s ease-out;
    -o-transition: background .7s ease-out;
    transition: background .7s ease-out;
}

@media (max-width: 991px) {
    /* 991px */
    .quick-nav {
        top: 120px;
        margin-top: 0;
    }
}

html body.blank-page {
    width: auto;
    height: 100%;
    background: url('/images/logos/header_stel_cropped.jpg') no-repeat center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

nav.navbar .dropdown-menu > .disabled > a:hover,
nav.navbar .dropdown-menu > .disabled > a:focus {
    cursor: not-allowed;
}

nav.navbar .dropdown-menu > .disabled > a:not([href='#']):hover,
nav.navbar .dropdown-menu > .disabled > a:not([href='#']):focus {
    cursor: pointer;
}

.loader-holder {
    position:fixed;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.4);
    z-index: 100;
}

.loader {
    border: 12px solid #f3f3f3; /* Light grey */
    border-top: 12px solid #f0b500; /* Wocozon color */
    border-radius: 50% !important;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
