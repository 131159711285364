.fa-spin-custom,
.glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.table-warning {
    td {
        background-color: #f0ad4e !important;
        cursor: not-allowed;
    }

    &:hover > td {
        background-color: #f0ad4e !important;
    }
}

.page-content .dataTables_wrapper .dataTables_filter {
    text-align: left;
}

tr.selected {
    .modal-dialog {
        color: #333333;
    }
}

#search-form {
    .row {
        margin-bottom: 10px;
    }
}

.w-select {
    &--active {
        border: 1px solid #337ab7;
    }

    &--selected {
        font-weight: bold;
        color: #337ab7;
    }
}

.w-switch--active {
    font-weight: bold;
    border: 1px solid #337ab7;
}

#active-filters .badge {
    margin-left: 2px;
    font-size: 14px !important;
    height: initial;
}

.w-label--active {
    font-weight: 600;
    color: #337ab7;

    &:before {
        content: "∷";
        display: inline-block;
        margin-right: 2px;
    }
}
