@import "variables";
@import "../../../node_modules/flatpickr/dist/flatpickr.min.css";
@import "../../../node_modules/flatpickr/dist/themes/material_blue.css";
@import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../../node_modules/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";
@import "../../../node_modules/sweetalert2/dist/sweetalert2.css";
@import "../../../node_modules/font-awesome/css/font-awesome.min.css";
@import "../../../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../../../node_modules/nouislider/distribute/nouislider.css";
@import "../../../node_modules/dropzone/dist/basic.css";
@import "../../../node_modules/dropzone/dist/dropzone.css";
@import "../../../node_modules/jquery-typeahead/dist/jquery.typeahead.min.css";
@import "../../../node_modules/select2/dist/css/select2.min.css";
@import "../../../node_modules/select2-bootstrap-theme/dist/select2-bootstrap.min.css";
@import "../../../node_modules/multiselect/css/multi-select.css";
@import "../../../node_modules/datatables.net-bs/css/dataTables.bootstrap.min.css";
@import "../../../node_modules/datatables.net-buttons-bs/css/buttons.bootstrap.min.css";
@import "../../../node_modules/datatables.net-responsive-bs/css/responsive.bootstrap.min.css";
@import "../../../node_modules/datatables.net-scroller-bs/css/scroller.bootstrap.min.css";
@import "../../../node_modules/datatables.net-select-bs/css/select.bootstrap.min.css";
@import "../../../node_modules/x-editable/dist/jquery-editable/css/jquery-editable.css";
@import "../css/components";
@import "../css/plugins.css";
@import "../css/layout.css";
@import "../css/jquery-ui.min.css";
@import "../css/custom.css";
@import "custom";
